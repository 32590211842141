import { useMutation } from "@apollo/client";

import { jsonParseOrReturn } from "@smart/itops-utils-basic";

import { useQueryFactory } from "./base";
import { mutationDocuments, queryDocuments } from "../generated";

export const useLoadDeadLetters = useQueryFactory(queryDocuments.deadLetters, {
  map: ({ deadLetters }) =>
    deadLetters?.map((i) => ({
      ...i,
      content: jsonParseOrReturn(i.content),
      action: i.action || "",
    })),
});

export const useUpdateDeadLetters = () =>
  useMutation(mutationDocuments.updateDeadLetters);
