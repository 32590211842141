import Swagger from "swagger-ui-react";
import "swagger-ui-react/swagger-ui.css";

export type ApiViewProps = {
  definition: string;
};

const ApiView = ({ definition }: ApiViewProps) => (
  <Swagger spec={definition} supportedSubmitMethods={[]} />
);

export { ApiView };
