import { useState } from "react";

import { useLoadApis } from "@smart/bot-gql-client-dom";
import { Column, GqlStatusOverlay } from "@smart/itops-components-dom";

import { ApiList, ApiView } from "../components";

declare const SF_VERSION: string;

const Apis = () => {
  const version = useState(SF_VERSION);
  const api = useState<string>();
  const load = useLoadApis({ version: version[0] });
  const found = load.result?.find((a) => a.name === api[0]);

  return (
    <Column>
      <ApiList
        version={version}
        apis={load.result?.map((a) => a.name) || []}
        api={api}
      />
      {found && <ApiView definition={found.definition} />}
      <GqlStatusOverlay {...load} />
    </Column>
  );
};

export { Apis };
