/* eslint-disable */
import {
  DeadLetterFieldsFragmentDoc,
  GqlDeadLetterFieldsFragment,
} from "./dead-letter-fields";
import {
  DeploymentFieldsFragmentDoc,
  GqlDeploymentFieldsFragment,
} from "./deployment-fields";
import {
  UpdateDeadLettersDocument,
  GqlUpdateDeadLettersMutation,
  GqlUpdateDeadLettersMutationVariables,
} from "./update-dead-letters";
import { ApisDocument, GqlApisQuery, GqlApisQueryVariables } from "./apis";
import {
  BundleAnalysisDocument,
  GqlBundleAnalysisQuery,
  GqlBundleAnalysisQueryVariables,
} from "./bundle-analysis";
import {
  BundlesDocument,
  GqlBundlesQuery,
  GqlBundlesQueryVariables,
} from "./bundles";
import {
  DeadLettersDocument,
  GqlDeadLettersQuery,
  GqlDeadLettersQueryVariables,
} from "./dead-letters";
import {
  DeploymentDocument,
  GqlDeploymentQuery,
  GqlDeploymentQueryVariables,
} from "./deployment";

export const queryKeys = [
  "apis",
  "bundleAnalysis",
  "bundles",
  "deadLetters",
  "deployment",
] as const;
export type QueryKey = (typeof queryKeys)[number];
export const queryDocuments = {
  apis: ApisDocument,
  bundleAnalysis: BundleAnalysisDocument,
  bundles: BundlesDocument,
  deadLetters: DeadLettersDocument,
  deployment: DeploymentDocument,
};

export const mutationKeys = ["updateDeadLetters"] as const;
export type MutationKey = (typeof mutationKeys)[number];
export const mutationDocuments = {
  updateDeadLetters: UpdateDeadLettersDocument,
};

export const subscriptionKeys = [] as const;
export type SubscriptionKey = (typeof subscriptionKeys)[number];
export const subscriptionDocuments = {};

export const fragments = {
  deadLetterFields: DeadLetterFieldsFragmentDoc,
  deploymentFields: DeploymentFieldsFragmentDoc,
};
export namespace Gql {
  export type DeadLetterFieldsFragment = GqlDeadLetterFieldsFragment;
  export type DeploymentFieldsFragment = GqlDeploymentFieldsFragment;
  export type UpdateDeadLettersMutation = GqlUpdateDeadLettersMutation;
  export type UpdateDeadLettersMutationVariables =
    GqlUpdateDeadLettersMutationVariables;
  export type ApisQuery = GqlApisQuery;
  export type ApisQueryVariables = GqlApisQueryVariables;
  export type BundleAnalysisQuery = GqlBundleAnalysisQuery;
  export type BundleAnalysisQueryVariables = GqlBundleAnalysisQueryVariables;
  export type BundlesQuery = GqlBundlesQuery;
  export type BundlesQueryVariables = GqlBundlesQueryVariables;
  export type DeadLettersQuery = GqlDeadLettersQuery;
  export type DeadLettersQueryVariables = GqlDeadLettersQueryVariables;
  export type DeploymentQuery = GqlDeploymentQuery;
  export type DeploymentQueryVariables = GqlDeploymentQueryVariables;
}
