import { useState } from "react";

import { DeploymentProps } from "@smart/bot-gql-client-dom";
import { Page, Select, Tags, Toggle } from "@smart/itops-components-dom";
import { useTime } from "@smart/itops-hooks-dom";
import { specialChars } from "@smart/itops-utils-basic";

import { Full } from "./full";
import { Item } from "./item";

type PreviewDisplayProps = {
  data: DeploymentProps;
};

const PreviewDisplay = ({ data: { app, stacks } }: PreviewDisplayProps) => {
  const stackNames = Object.keys(stacks);
  const [source, setSource] = useState(false);
  const [stack, setStack] = useState(stackNames[0]);
  const time = useTime(stacks[stack].created);

  return (
    <Page
      text={app}
      top={
        <Tags
          tags={[
            stacks[stack].version,
            `${stackNames.length} Stacks`,
            time || specialChars.infinity,
          ]}
          titles={["Version", "Stacks", "Created At"]}
        />
      }
      right={
        <>
          <Select
            value={stack}
            onChange={(e) => setStack(e.currentTarget.value)}
          >
            {stackNames.map((s) => (
              <option key={s} value={s}>
                {s}
              </option>
            ))}
          </Select>
          <Toggle
            size={5}
            title="View Source"
            toggled={source}
            onClick={() => setSource(!source)}
          />
        </>
      }
    >
      {source ? (
        <Full changes={stacks[stack].original} />
      ) : (
        stacks[stack].changes.map((item) => (
          <Item key={item.id} change={item} />
        ))
      )}
    </Page>
  );
};

export { PreviewDisplay, PreviewDisplayProps };
