import { OperationVariables, useQuery } from "@apollo/client";
import { TypedDocumentNode } from "@graphql-typed-document-node/core";
import { useEffect } from "react";

export const useQueryFactory =
  <D, V extends OperationVariables, I>(
    document: TypedDocumentNode<D, V>,
    factoryOptions?: { map?: (d: D) => I },
  ) =>
  (variables: V, options?: { reload?: boolean }) => {
    const query = useQuery(document, {
      variables,
    });
    useEffect(() => {
      if (query.data && options?.reload) query.refetch().catch(console.error);
    }, []);

    return {
      ...query,
      result:
        factoryOptions?.map && query.data && factoryOptions.map(query.data),
    };
  };
