import {
  useLoadDeadLetters,
  useUpdateDeadLetters,
} from "@smart/bot-gql-client-dom";
import { GqlStatusOverlay, ListTable } from "@smart/itops-components-dom";
import { Env, Region } from "@smart/itops-utils-basic";

type DeadLetterTableProps = { env: Env; region: Region; entity?: string };

const DeadLetterTable = ({ env, region, entity }: DeadLetterTableProps) => {
  const deadLetters = useLoadDeadLetters({ env, region, entity });
  const [update] = useUpdateDeadLetters();

  return (
    <>
      {deadLetters.result && (
        <ListTable
          actions={[
            [
              "Requeue",
              "refresh",
              (ids) =>
                update({ variables: { env, region, ids, action: "requeue" } }),
            ],
            [
              "Delete",
              "cancel",
              (ids) =>
                update({ variables: { env, region, ids, action: "delete" } }),
            ],
          ]}
          refresh={() => deadLetters.refetch()}
          items={deadLetters.result}
          keyField="id"
          plural="items"
          columns={[
            "system",
            "entity",
            "sentAt",
            "deadAt",
            "content",
            "action",
          ]}
          searchColumns={["system", "entity"]}
        />
      )}
      <GqlStatusOverlay {...deadLetters} />
    </>
  );
};

export { DeadLetterTable, DeadLetterTableProps };
