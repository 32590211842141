import { createRoot } from "react-dom/client";
import { HelmetProvider } from "react-helmet-async";
import { BrowserRouter } from "react-router-dom";

import {
  ThemeProvider,
  defaultTheme,
  GlobalStyles,
} from "@smart/itops-components-dom";

import { Menu, SignIn } from "./components";
import { ClientProvider, UserProvider } from "./hooks";
import { RouteSelector } from "./routes";

declare const SF_IS_LOCAL: string;

export const isLocal = !!SF_IS_LOCAL;

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(
  <HelmetProvider>
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <UserProvider signIn={<SignIn />}>
        <BrowserRouter>
          <ClientProvider>
            <Menu>
              <RouteSelector />
            </Menu>
          </ClientProvider>
        </BrowserRouter>
      </UserProvider>
    </ThemeProvider>
  </HelmetProvider>,
);
