import { useNavigate } from "react-router-dom";

import {
  Button,
  InlineForm,
  Input,
  Label,
  Overlay,
} from "@smart/itops-components-dom";

const PreviewList = () => {
  const navigate = useNavigate();

  return (
    <Overlay icon="preview" subHeading="Enter A Deployment Id" level="DEFAULT">
      <InlineForm
        onSubmit={(e) => {
          e.preventDefault();
          const id = new FormData(e.currentTarget).get("id");
          navigate(`/preview/Deployments-${id}`);
        }}
      >
        <Label name="id" text="Deployment-">
          <Input name="id" placeholder="123456" type="number" />
        </Label>
        <Button type="submit">Go</Button>
      </InlineForm>
    </Overlay>
  );
};

export { PreviewList };
