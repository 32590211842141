import { TypedTypePolicies } from "../generated/config";

export const typePolicies: TypedTypePolicies = {
  Api: {
    keyFields: ["key"],
  },
  BundleAnalysis: {
    keyFields: ["key"],
  },
  Query: {
    fields: {},
  },
  Change: {
    keyFields: false,
  },
  ChangeDetail: {
    keyFields: false,
  },
  ChangeSet: {
    keyFields: false,
  },
  ChangeTarget: {
    keyFields: false,
  },
  DeadLetter: {
    keyFields: ["id"],
  },
  Deployment: {
    keyFields: ["key"],
  },
  ResourceChange: {
    keyFields: false,
  },
};
