import { smart } from "@smart/bridge-images-dom";
import { LinkButton, Overlay } from "@smart/itops-components-dom";

const SignIn = () => (
  <Overlay
    image={smart}
    heading="MatterIntake Dev Tools"
    subHeading="Use your authorized Slack account to sign in below"
    level="DEFAULT"
  >
    <p>
      <br />
      <LinkButton href="/api/code">Sign In With Slack</LinkButton>
    </p>
  </Overlay>
);

export { SignIn };
