import { useState } from "react";

import { useLoadBundles } from "@smart/bot-gql-client-dom";
import { Column, GqlStatusOverlay } from "@smart/itops-components-dom";

import { PackageList, PackageView } from "../components";

declare const SF_VERSION: string;

const BundleAnalysis = () => {
  const version = useState(SF_VERSION);
  const pkg = useState("");
  const load = useLoadBundles({ version: version[0] });

  return (
    <Column>
      <PackageList version={version} packages={load.result} pkg={pkg} />
      <PackageView version={version} pkg={pkg} />
      <GqlStatusOverlay {...load} />
    </Column>
  );
};

export { BundleAnalysis };
