import styled from "@emotion/styled";

import { Input, Label, Pill, PillRow } from "@smart/itops-components-dom";

const ApiListWrapper = styled(PillRow)`
  .label {
    width: 15rem;
  }
`;

export type ApiListProps = {
  apis: string[];
  version: [string, (version: string) => void];
  api: [string | undefined, (api: string) => void];
};

const ApiList = ({
  apis,
  version: [version, setVersion],
  api: [selectedApi, selectApi],
}: ApiListProps) => (
  <ApiListWrapper>
    <Label name="version" text="Version">
      <Input
        value={version}
        name="version"
        onChange={(e) => setVersion(e.currentTarget.value)}
      />
    </Label>
    {apis.map((api) => (
      <Pill
        key={api}
        text={api}
        selected={selectedApi === api}
        onClick={() => selectApi(api)}
      />
    ))}
  </ApiListWrapper>
);

export { ApiList };
