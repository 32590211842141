import styled from "@emotion/styled";

import { useLoadBundleAnalysis } from "@smart/bot-gql-client-dom";

const PackageViewWrapper = styled.iframe`
  border: 0;
  overflow: scroll !important;
  flex: 1;
`;

export type PackageViewProps = {
  pkg: [string, (pkg: string) => void];
  version: [string, (version: string) => void];
};

const PackageView = ({ pkg: [pkg], version: [version] }: PackageViewProps) => {
  const { result } = useLoadBundleAnalysis({ version, pkg });

  return <PackageViewWrapper srcDoc={result?.content} />;
};

export { PackageView };
