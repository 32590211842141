import { useState } from "react";

import { Column } from "@smart/itops-components-dom";
import { Env, Region } from "@smart/itops-utils-basic";

import { Entities, DeadLetterTable } from "../components";

const DeadLetters = () => {
  const region = useState<Region>("aus");
  const env = useState<Env>("live");
  const entity = useState<string>();

  return (
    <Column>
      <Entities entity={entity} env={env} region={region} />
      <DeadLetterTable entity={entity[0]} env={env[0]} region={region[0]} />
    </Column>
  );
};

export { DeadLetters };
