import { useQueryFactory } from "./base";
import { queryDocuments } from "../generated";

export const useLoadBundles = useQueryFactory(queryDocuments.bundles, {
  map: ({ bundles }) => bundles,
});

export const useLoadBundleAnalysis = useQueryFactory(
  queryDocuments.bundleAnalysis,
  {
    map: ({ bundleAnalysis }) => bundleAnalysis,
  },
);
