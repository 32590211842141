import { useLoadDeployment } from "@smart/bot-gql-client-dom";
import { GqlStatusOverlay } from "@smart/itops-components-dom";
import { useParamsWithDefault } from "@smart/itops-hooks-dom";

import { PreviewDisplay } from "../components";

const Preview = () => {
  const params = useParamsWithDefault((p) => ({
    key: p.key || "",
  }));
  const deployment = useLoadDeployment(params);

  return (
    <>
      <GqlStatusOverlay {...deployment} />
      {deployment.result && <PreviewDisplay data={deployment.result} />}
    </>
  );
};

export { Preview };
