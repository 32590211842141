import { useRoutes } from "react-router-dom";

import { BundleAnalysis } from "./analysis";
import { Apis } from "./apis";
import { DeadLetters } from "./dead-letters";
import { Home } from "./home";
import { NotFound } from "./not-found";
import { Preview } from "./preview";
import { PreviewList } from "./preview-list";
import { SignOut } from "./sign-out";

const RouteSelector = () =>
  useRoutes([
    { index: true, element: <Home /> },
    { path: "/apis", element: <Apis /> },
    { path: "/analysis", element: <BundleAnalysis /> },
    { path: "/dlqs", element: <DeadLetters /> },
    { path: "/preview", element: <PreviewList /> },
    { path: "/preview/:key", element: <Preview /> },
    { path: "/signout", element: <SignOut /> },
    { path: "*", element: <NotFound /> },
  ]);

export { RouteSelector };
