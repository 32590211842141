/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlUpdateDeadLettersMutationVariables = Types.Exact<{
  env: Types.Scalars["String"]["input"];
  region: Types.Scalars["String"]["input"];
  action: Types.GqlAction;
  ids:
    | Array<Types.Scalars["String"]["input"]>
    | Types.Scalars["String"]["input"];
}>;

export type GqlUpdateDeadLettersMutation = {
  __typename: "Mutation";
  updateDeadLetters: Array<{
    __typename: "DeadLetter";
    id: string;
    system: string;
    entity: string;
    sentAt: string;
    deadAt: string;
    notifiedAt?: string | null;
    content?: string | null;
    action?: Types.GqlAction | null;
  }>;
};

export const UpdateDeadLettersDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "mutation",
      name: { kind: "Name", value: "updateDeadLetters" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "env" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "region" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "String" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: {
            kind: "Variable",
            name: { kind: "Name", value: "action" },
          },
          type: {
            kind: "NonNullType",
            type: {
              kind: "NamedType",
              name: { kind: "Name", value: "Action" },
            },
          },
        },
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "ids" } },
          type: {
            kind: "NonNullType",
            type: {
              kind: "ListType",
              type: {
                kind: "NonNullType",
                type: {
                  kind: "NamedType",
                  name: { kind: "Name", value: "String" },
                },
              },
            },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "updateDeadLetters" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "env" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "env" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "region" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "region" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "action" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "action" },
                },
              },
              {
                kind: "Argument",
                name: { kind: "Name", value: "ids" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "ids" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "deadLetterFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "deadLetterFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "DeadLetter" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "id" } },
          { kind: "Field", name: { kind: "Name", value: "system" } },
          { kind: "Field", name: { kind: "Name", value: "entity" } },
          { kind: "Field", name: { kind: "Name", value: "sentAt" } },
          { kind: "Field", name: { kind: "Name", value: "deadAt" } },
          { kind: "Field", name: { kind: "Name", value: "notifiedAt" } },
          { kind: "Field", name: { kind: "Name", value: "content" } },
          { kind: "Field", name: { kind: "Name", value: "action" } },
        ],
      },
    },
  ],
} as unknown as DocumentNode<
  GqlUpdateDeadLettersMutation,
  GqlUpdateDeadLettersMutationVariables
>;
