import { ApolloProvider } from "@apollo/client";
import { ReactNode, useEffect, useState } from "react";

import { buildClient, buildLink } from "@smart/bot-gql-client-dom";

import { useUser } from "./user";

export type ClientProviderProps = { children: ReactNode };

export const ClientProvider = ({ children }: ClientProviderProps) => {
  const { token, signOut } = useUser();
  const [client] = useState(() => buildClient({ token, refresh: signOut }));
  useEffect(() => {
    client.setLink(buildLink({ token, refresh: signOut }));
  }, [token, signOut]);

  return <ApolloProvider client={client}>{children}</ApolloProvider>;
};
