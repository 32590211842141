import { ReactNode } from "react";

import { Sidebar, SidebarLink } from "@smart/itops-components-dom";

const links: SidebarLink[] = [
  {
    to: "/apis",
    label: "Api Documentation",
    icon: "book",
  },
  {
    to: "/analysis",
    label: "Bundle Analysis",
    icon: "businessChart",
  },
  {
    to: "/dlqs",
    label: "Dead Letters",
    icon: "brokenLink",
  },
  {
    to: "/preview",
    label: "Change Set Previews",
    icon: "preview",
  },
];

export type MenuProps = {
  children: ReactNode;
};

const Menu = ({ children }: MenuProps) => (
  <Sidebar links={links}>{children}</Sidebar>
);

export { Menu };
