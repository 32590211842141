import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { transparentize } from "polished";

import { ChangeProps } from "@smart/bot-gql-client-dom";
import { specialChars } from "@smart/itops-utils-basic";

type ItemProps = {
  change: ChangeProps;
};

const actionColour: Record<string, keyof Theme["palette"]> = {
  Add: "success",
  Modify: "primary",
  Remove: "danger",
};

const ItemWrapper = styled.div<{ danger: boolean; action: string }>`
  background: ${(props) =>
    props.danger
      ? transparentize(0.8, props.theme.palette.danger.base)
      : "var(--background)"};
  border-bottom: 1px dotted ${(props) => props.theme.palette.foreground.accent};
  margin: 1rem 0;
  padding: 0.8rem 1.4rem;

  .title {
    display: flex;
    flex-flow: row;
    align-items: center;

    .action {
      margin: 0 1rem 0 0;
      font-size: ${(props) => props.theme.fontSize.xSmall};
      font-weight: bold;
      background-color: ${(props) =>
        props.theme.palette[actionColour[props.action] || "primary"].base};
      color: ${(props) => props.theme.palette.background.base};
      padding: 0.4rem 1rem;
      border-radius: 100px;
    }

    h2 {
      font-size: ${(props) => props.theme.fontSize.base};
      font-weight: bold;
      margin: 0;
    }

    h3 {
      font-size: ${(props) => props.theme.fontSize.small};
      font-weight: normal;
      margin: 0 0 0 auto;
    }
  }

  ul {
    padding-left: 2.4rem;
    margin: 1rem 0 0;

    li {
      font-size: ${(props) => props.theme.fontSize.small};

      small {
        font-size: ${(props) => props.theme.fontSize.xSmall};
        font-weight: bold;
      }
    }
  }
`;

const Item = ({
  change: { id, replacement, action, resource, details },
}: ItemProps) => (
  <ItemWrapper danger={!!replacement || action === "Remove"} action={action}>
    <div className="title">
      <p className="action">
        {action}
        {replacement ? " (Replace)" : ""}
      </p>
      <h2>{id}</h2>
      <h3>{resource}</h3>
    </div>
    {details && (
      <ul>
        {details.map(({ name, attribute, source }) => (
          <li key={name}>
            {name}{" "}
            <small>
              {specialChars.enDash} {source} of {attribute}
            </small>
          </li>
        ))}
      </ul>
    )}
  </ItemWrapper>
);

export { Item, ItemProps };
