/* eslint-disable */
import * as Types from "./types";

import { TypedDocumentNode as DocumentNode } from "@graphql-typed-document-node/core";
export type GqlDeploymentQueryVariables = Types.Exact<{
  key: Types.Scalars["ID"]["input"];
}>;

export type GqlDeploymentQuery = {
  __typename: "Query";
  deployment?: {
    __typename: "Deployment";
    key: string;
    changes?: Array<{
      __typename: "ChangeSet";
      ChangeSetId: string;
      ChangeSetName?: string | null;
      StackId?: string | null;
      StackName?: string | null;
      CreationTime?: string | null;
      Changes?: Array<{
        __typename: "Change";
        Type: string;
        ResourceChange?: {
          __typename: "ResourceChange";
          Action?: string | null;
          LogicalResourceId?: string | null;
          PhysicalResourceId?: string | null;
          ResourceType?: string | null;
          Replacement?: string | null;
          Details?: Array<{
            __typename: "ChangeDetail";
            ChangeSource?: string | null;
            Target?: {
              __typename: "ChangeTarget";
              Attribute?: string | null;
              Name?: string | null;
            } | null;
          }> | null;
        } | null;
      }> | null;
    }> | null;
  } | null;
};

export const DeploymentDocument = {
  kind: "Document",
  definitions: [
    {
      kind: "OperationDefinition",
      operation: "query",
      name: { kind: "Name", value: "deployment" },
      variableDefinitions: [
        {
          kind: "VariableDefinition",
          variable: { kind: "Variable", name: { kind: "Name", value: "key" } },
          type: {
            kind: "NonNullType",
            type: { kind: "NamedType", name: { kind: "Name", value: "ID" } },
          },
        },
      ],
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          {
            kind: "Field",
            name: { kind: "Name", value: "deployment" },
            arguments: [
              {
                kind: "Argument",
                name: { kind: "Name", value: "key" },
                value: {
                  kind: "Variable",
                  name: { kind: "Name", value: "key" },
                },
              },
            ],
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                {
                  kind: "FragmentSpread",
                  name: { kind: "Name", value: "deploymentFields" },
                },
              ],
            },
          },
        ],
      },
    },
    {
      kind: "FragmentDefinition",
      name: { kind: "Name", value: "deploymentFields" },
      typeCondition: {
        kind: "NamedType",
        name: { kind: "Name", value: "Deployment" },
      },
      selectionSet: {
        kind: "SelectionSet",
        selections: [
          { kind: "Field", name: { kind: "Name", value: "key" } },
          {
            kind: "Field",
            name: { kind: "Name", value: "changes" },
            selectionSet: {
              kind: "SelectionSet",
              selections: [
                { kind: "Field", name: { kind: "Name", value: "ChangeSetId" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "ChangeSetName" },
                },
                { kind: "Field", name: { kind: "Name", value: "StackId" } },
                { kind: "Field", name: { kind: "Name", value: "StackName" } },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "CreationTime" },
                },
                {
                  kind: "Field",
                  name: { kind: "Name", value: "Changes" },
                  selectionSet: {
                    kind: "SelectionSet",
                    selections: [
                      { kind: "Field", name: { kind: "Name", value: "Type" } },
                      {
                        kind: "Field",
                        name: { kind: "Name", value: "ResourceChange" },
                        selectionSet: {
                          kind: "SelectionSet",
                          selections: [
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Action" },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "LogicalResourceId",
                              },
                            },
                            {
                              kind: "Field",
                              name: {
                                kind: "Name",
                                value: "PhysicalResourceId",
                              },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "ResourceType" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Replacement" },
                            },
                            {
                              kind: "Field",
                              name: { kind: "Name", value: "Details" },
                              selectionSet: {
                                kind: "SelectionSet",
                                selections: [
                                  {
                                    kind: "Field",
                                    name: {
                                      kind: "Name",
                                      value: "ChangeSource",
                                    },
                                  },
                                  {
                                    kind: "Field",
                                    name: { kind: "Name", value: "Target" },
                                    selectionSet: {
                                      kind: "SelectionSet",
                                      selections: [
                                        {
                                          kind: "Field",
                                          name: {
                                            kind: "Name",
                                            value: "Attribute",
                                          },
                                        },
                                        {
                                          kind: "Field",
                                          name: { kind: "Name", value: "Name" },
                                        },
                                      ],
                                    },
                                  },
                                ],
                              },
                            },
                          ],
                        },
                      },
                    ],
                  },
                },
              ],
            },
          },
        ],
      },
    },
  ],
} as unknown as DocumentNode<GqlDeploymentQuery, GqlDeploymentQueryVariables>;
