import { ApolloClient, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { HttpLink } from "@apollo/client/link/http";

import { typePolicies } from "./policy";

const hasStatusCode = (e: any): e is { statusCode: number } =>
  !!e && !!e.statusCode;

export type LinkOptions = { token: string; refresh: () => void };

export const buildLink = ({ token, refresh }: LinkOptions) => {
  const httpLink = new HttpLink({ uri: `/api/gql` });
  const authLink = setContext(() => ({
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }));
  const errorLink = onError(({ networkError }) => {
    if (hasStatusCode(networkError) && networkError.statusCode === 401)
      refresh();
  });

  return errorLink.concat(authLink.concat(httpLink));
};

export const buildClient = (options: LinkOptions) =>
  new ApolloClient({
    cache: new InMemoryCache({ typePolicies }),
    link: buildLink(options),
    connectToDevTools: true,
  });
