import styled from "@emotion/styled";

import { Input, Label, PillRow, Select } from "@smart/itops-components-dom";

const PackageListWrapper = styled(PillRow)`
  .label {
    width: fit-content;
    margin-right: 1rem;

    &:first-of-type {
      width: 10rem;
    }
  }
`;

export type PackageListProps = {
  packages?: string[];
  pkg: [string, (pkg: string) => void];
  version: [string, (version: string) => void];
};

const PackageList = ({
  packages,
  pkg: [pkg, setPkg],
  version: [version, setVersion],
}: PackageListProps) => (
  <PackageListWrapper>
    <Label name="version" text="Version">
      <Input
        value={version}
        onChange={(e) => setVersion(e.currentTarget.value)}
      />
    </Label>
    <Label name="pkg" text="Package" loading={!packages}>
      <Select name="pkg" value={pkg} onChange={(e) => setPkg(e.target.value)}>
        <option value="" disabled>
          Select a Package
        </option>
        {packages?.map((p) => (
          <option key={p} value={p}>
            {p}
          </option>
        ))}
      </Select>
    </Label>
  </PackageListWrapper>
);

export { PackageList };
