import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { Overlay } from "@smart/itops-components-dom";

import { useUser } from "../hooks";

const SignOut = () => {
  const navigate = useNavigate();
  const { signOut } = useUser();
  useEffect(() => {
    signOut();
    navigate("/", { replace: true });
  }, []);

  return <Overlay subHeading="Signing Out..." />;
};

export { SignOut };
