import { envImages, regionImages } from "@smart/bridge-images-dom";
import { transportKeys } from "@smart/bridge-resources-basic";
import { OptionsButton, Pill, PillRow } from "@smart/itops-components-dom";
import { Env, envs, Region, regions } from "@smart/itops-utils-basic";

const EntitiesWrapper = PillRow;

export type EntitiesProps = {
  entity: [string | undefined, (entity: string) => void];
  env: [Env, (env: Env) => void];
  region: [Region, (region: Region) => void];
};

const Entities = ({
  entity: [selectedEntity, selectEntity],
  env: [selectedEnv, selectEnv],
  region: [selectedRegion, selectRegion],
}: EntitiesProps) => (
  <EntitiesWrapper>
    <OptionsButton
      options={envs}
      select={selectEnv}
      selected={selectedEnv}
      images={envImages}
    />
    <OptionsButton
      options={regions}
      select={selectRegion}
      selected={selectedRegion}
      images={regionImages}
    />
    {transportKeys.map((entity) => (
      <Pill
        key={entity}
        text={entity}
        selected={selectedEntity === entity}
        onClick={() => selectEntity(entity)}
      />
    ))}
  </EntitiesWrapper>
);

export { Entities };
