import { useQueryFactory } from "./base";
import { queryDocuments } from "../generated";

export type ChangeProps = {
  id: string;
  action: string;
  resource: string;
  replacement?: boolean;
  details?: {
    attribute: string;
    name: string;
    source: string;
  }[];
};

export type DeploymentProps = {
  app: string;
  stacks: Record<
    string,
    {
      created?: Date;
      version: string;
      changes: ChangeProps[];
      original: string;
    }
  >;
};

export const useLoadDeployment = useQueryFactory(queryDocuments.deployment, {
  map: ({ deployment }) => {
    const data: DeploymentProps = {
      app: deployment?.key || "Not Found",
      stacks: {},
    };
    for (const stack of deployment?.changes || []) {
      const id = stack.StackName || stack.StackId;
      if (id) {
        data.stacks[id] = {
          created: stack.CreationTime
            ? new Date(stack.CreationTime)
            : undefined,
          version: stack.ChangeSetName || "",
          changes: (stack.Changes || []).map((c, i) => ({
            id:
              c.ResourceChange?.LogicalResourceId ||
              c.ResourceChange?.PhysicalResourceId ||
              `${i}`,
            action: c.ResourceChange?.Action || "",
            resource: c.ResourceChange?.ResourceType || "",
            replacement: c.ResourceChange?.Replacement === "True" || false,
            details: (c.ResourceChange?.Details || []).map((d, j) => ({
              attribute: d.Target?.Attribute || "",
              name: d.Target?.Name || `${j}`,
              source: d.ChangeSource || "",
            })),
          })),
          original: JSON.stringify(stack, null, 2),
        };
      }
    }

    return data;
  },
});
