import styled from "@emotion/styled";

type FullProps = {
  changes: string;
};

const Text = styled.pre`
  background: ${(props) => props.theme.palette.background.accent};
  font-family: monospace;
  font-size: ${(props) => props.theme.fontSize.small};
  line-height: 1.6;
  overflow: auto;
  padding: 1rem;
`;

const Full = ({ changes }: FullProps) => <Text>{changes}</Text>;

export { Full, FullProps };
