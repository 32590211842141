import hands from "./bg/hands.jpg";
import excel from "./file-types/excel.png";
import file from "./file-types/file.png";
import image from "./file-types/image.png";
import outlook from "./file-types/outlook.png";
import pdf from "./file-types/pdf.png";
import powerpoint from "./file-types/powerpoint.png";
import word from "./file-types/word.png";
import archie2 from "./icon/archie-2.svg";
import archie from "./icon/archie.svg";
import aus from "./icon/au.svg";
import google from "./icon/google.png";
import live from "./icon/live.svg";
import local from "./icon/local.svg";
import lon from "./icon/lon.svg";
import nova from "./icon/nova.svg";
import practiceevolveFull from "./icon/practiceevolve-full.png";
import practiceevolve from "./icon/practiceevolve.png";
import smart from "./icon/smart.png";
import smokeballFull from "./icon/smokeball-full.svg";
import smokeball from "./icon/smokeball.png";
import staging from "./icon/staging.svg";
import triconveyFull from "./icon/triconvey-full.png";
import triconvey from "./icon/triconvey.png";
import documentAutomation from "./illustration/document-automation.svg";
import leadManagement from "./illustration/lead-management.svg";
import migration from "./illustration/migration.svg";

export const envImages = { local, dev: local, staging, live };

export const regionImages = { aus, nova, lon };

export {
  google,
  hands,
  practiceevolveFull,
  practiceevolve,
  smart,
  smokeballFull,
  smokeball,
  triconveyFull,
  triconvey,
  documentAutomation,
  leadManagement,
  migration,
  archie,
  archie2,
};

export const fileTypes = {
  pdf,
  txt: file,
  jpg: image,
  png: image,
  svg: image,
  bmp: image,
  csv: excel,
  xls: excel,
  xlsx: excel,
  msg: outlook,
  ppt: powerpoint,
  pptx: powerpoint,
  doc: word,
  docx: word,
};
